import React, {useReducer} from "react";
import {Link as Link2} from "react-scroll";
import {useTranslation} from "react-i18next";
import Navbar from "../component/navbar";
import Footer from "../component/footer";

import {FaLifeRing} from "../assets/icons/icons";
import {FaCommentsDollar} from "react-icons/fa6";
import {FaQuestionCircle} from "react-icons/fa";

import GetInTouch from "../component/getInTouch";
import {faqsData} from "../data/faqs";

export default function Faqs() {
  const {t} = useTranslation();

  document.title = t("PageTitles.FAQs");

  const initialState = {
    activeGeneral: 0,
    activePayment: 0,
    activeSupport: 0,
  };

  const toggleAccordion = (category, index) => {
    dispatch({type: "SELECT_CATEGORY", payload: {category: category, index: index}});
  };

  const reducerMethod = (state, action) => {
    switch (action.payload.category) {
      case "general":
        return {
          ...state,
          activeGeneral: action.payload.index,
        };
      case "payments":
        return {
          ...state,
          activePayment: action.payload.index,
        };
      case "support":
        return {
          ...state,
          activeSupport: action.payload.index,
        };
      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(reducerMethod, initialState);

  const navigation = [
    {NameKey: "FaqsData.Categories.GENERAL", Href: "general", Icon: FaQuestionCircle},
    {NameKey: "FaqsData.Categories.PAYMENTS", Href: "payment", Icon: FaCommentsDollar},
    {NameKey: "FaqsData.Categories.SUPPORT", Href: "support", Icon: FaLifeRing},
  ];

  return (
    <>
      <Navbar />

      <section className="relative table w-full py-32 lg:py-40 bg-gray-50 dark:bg-slate-800">
        <div className="container relative">
          <div className="grid grid-cols-1 text-center mt-10">
            <h4 className="text-3xl leading-normal font-semibold">{t("PageNames.FAQs")}</h4>
          </div>
        </div>
      </section>

      <div className="relative">
        <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
          <svg className="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
          </svg>
        </div>
      </div>

      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
            <div className="lg:col-span-4 md:col-span-5">
              <div className="rounded-md shadow dark:shadow-gray-800 p-6 sticky top-20">
                <nav aria-label="Sidebar" className="flex flex-1 flex-col">
                  <ul className="-mx-2 space-y-1">
                    {navigation.map((item, index) => (
                      <li key={index}>
                        <Link2
                          to={item.Href}
                          spy={true}
                          duration={500}
                          offset={-90}
                          activeClass="faq-active"
                          className="text-gray-700 hover:bg-gray-50 hover:text-indigo-600 group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 cursor-pointer">
                          <item.Icon aria-hidden="true" className="text-gray-400 group-hover:text-indigo-600 h-6 w-6 shrink-0" />
                          {t(item.NameKey)}
                        </Link2>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>
            </div>

            <div className="lg:col-span-8 md:col-span-7">
              <div id="general">
                <h5 className="text-2xl font-semibold">{t("FaqsData.Categories.GENERAL")}</h5>

                <div id="accordion-collapsetwo" data-accordion="collapse" className="mt-6">
                  {faqsData
                    .filter((item) => item.Category === "GENERAL")
                    .map((item, index) => (
                      <div key={index} className={`relative shadow dark:shadow-gray-700 rounded-md overflow-hidden mt-4`}>
                        <h2 className="text-base font-medium">
                          <button
                            onClick={() => toggleAccordion("general", index)}
                            type="button"
                            className={`flex justify-between items-center p-5 w-full font-semibold text-start ${state.activeGeneral === index ? "bg-gray-50 dark:bg-slate-800 text-violet-600" : ""} `}
                            data-accordion-target="#accordion-collapse-body-1"
                            aria-expanded="true"
                            aria-controls="accordion-collapse-body-1">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: t(`FaqsData.${item.Category}.${item.Id}.Q`),
                              }}></span>
                            <svg
                              data-accordion-icon
                              className={`${state.activeIndex === index ? "rotate-180" : "rotate-270"} size-4 shrink-01`}
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                            </svg>
                          </button>
                        </h2>
                        {state.activeGeneral === index && (
                          <div aria-labelledby="accordion-collapse-heading-1">
                            <div className="p-5">
                              <p
                                className="text-slate-600 dark:text-gray-600"
                                dangerouslySetInnerHTML={{
                                  __html: t(`FaqsData.${item.Category}.${item.Id}.A`),
                                }}></p>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              </div>

              <div id="payment" className="mt-8">
                <h5 className="text-2xl font-semibold">{t("FaqsData.Categories.PAYMENTS")}</h5>

                <div id="accordion-collapsethree" data-accordion="collapse" className="mt-6">
                  {faqsData
                    .filter((item) => item.Category === "PAYMENTS")
                    .map((item, index) => (
                      <div key={index} className={`relative shadow dark:shadow-gray-700 rounded-md overflow-hidden mt-4`}>
                        <h2 className="text-base font-medium">
                          <button
                            onClick={() => toggleAccordion("payments", index)}
                            type="button"
                            className={`flex justify-between items-center p-5 w-full font-semibold text-start ${state.activePayment === index ? "bg-gray-50 dark:bg-slate-800 text-violet-600" : ""} `}
                            data-accordion-target="#accordion-collapse-body-1"
                            aria-expanded="true"
                            aria-controls="accordion-collapse-body-1">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: t(`FaqsData.${item.Category}.${item.Id}.Q`),
                              }}></span>
                            <svg
                              data-accordion-icon
                              className={`${state.activeIndex === index ? "rotate-180" : "rotate-270"} size-4 shrink-01`}
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                            </svg>
                          </button>
                        </h2>
                        {state.activePayment === index && (
                          <div aria-labelledby="accordion-collapse-heading-1">
                            <div className="p-5">
                              <p
                                className="text-slate-600 dark:text-gray-600"
                                dangerouslySetInnerHTML={{
                                  __html: t(`FaqsData.${item.Category}.${item.Id}.A`),
                                }}></p>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              </div>

              <div id="support" className="mt-8">
                <h5 className="text-2xl font-semibold">{t("FaqsData.Categories.SUPPORT")}</h5>

                <div id="accordion-collapsefour" data-accordion="collapse" className="mt-6">
                  {faqsData
                    .filter((item) => item.Category === "SUPPORT")
                    .map((item, index) => (
                      <div key={index} className={`relative shadow dark:shadow-gray-700 rounded-md overflow-hidden mt-4`}>
                        <h2 className="text-base font-medium">
                          <button
                            onClick={() => toggleAccordion("support", index)}
                            type="button"
                            className={`flex justify-between items-center p-5 w-full font-semibold text-start ${state.activeSupport === index ? "bg-gray-50 dark:bg-slate-800 text-violet-600" : ""} `}
                            data-accordion-target="#accordion-collapse-body-1"
                            aria-expanded="true"
                            aria-controls="accordion-collapse-body-1">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: t(`FaqsData.${item.Category}.${item.Id}.Q`),
                              }}></span>
                            <svg
                              data-accordion-icon
                              className={`${state.activeIndex === index ? "rotate-180" : "rotate-270"} size-4 shrink-01`}
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                            </svg>
                          </button>
                        </h2>
                        {state.activeSupport === index && (
                          <div aria-labelledby="accordion-collapse-heading-1">
                            <div className="p-5">
                              <p
                                className="text-slate-600 dark:text-gray-600"
                                dangerouslySetInnerHTML={{
                                  __html: t(`FaqsData.${item.Category}.${item.Id}.A`),
                                }}></p>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <GetInTouch />
      </section>

      <Footer />
    </>
  );
}
