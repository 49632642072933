import React from "react";
import {useTranslation} from "react-i18next";

import {IoAnalyticsSharp, IoBugOutline} from "react-icons/io5";
import {MdChecklist, MdInsights, MdOutlineCloudUpload, MdOutlineScience} from "react-icons/md";
import {FaBoxesPacking} from "react-icons/fa6";
import {TbLayoutDashboard} from "react-icons/tb";
import {CgPerformance} from "react-icons/cg";

import imageStep1 from "../assets/images/step1-collect-asins.png";
import imageStep2 from "../assets/images/step2-detailed-analysis.png";
import imageStep3 from "../assets/images/step3-monitoring-sales.png";

const featuresStep1 = [
  {
    NameKey: "HowItWorks.FeaturesStep1.Features.ScanAndSaveModule.Title",
    DescriptionKey: "HowItWorks.FeaturesStep1.Features.ScanAndSaveModule.Description",
    Icon: IoBugOutline,
  },
  {
    NameKey: "HowItWorks.FeaturesStep1.Features.UserProvidedASINs.Title",
    DescriptionKey: "HowItWorks.FeaturesStep1.Features.UserProvidedASINs.Description",
    Icon: MdOutlineCloudUpload,
  },
  {
    NameKey: "HowItWorks.FeaturesStep1.Features.StartingNewAnalysis.Title",
    DescriptionKey: "HowItWorks.FeaturesStep1.Features.StartingNewAnalysis.Description",
    Icon: MdOutlineScience,
  },
];

const featuresStep2 = [
  {
    NameKey: "HowItWorks.FeaturesStep2.Features.ProfitCompetitorAnalysis.Title",
    DescriptionKey: "HowItWorks.FeaturesStep2.Features.ProfitCompetitorAnalysis.Description",
    Icon: IoAnalyticsSharp,
  },
  {
    NameKey: "HowItWorks.FeaturesStep2.Features.ProductChecklistControls.Title",
    DescriptionKey: "HowItWorks.FeaturesStep2.Features.ProductChecklistControls.Description",
    Icon: MdChecklist,
  },
  {
    NameKey: "HowItWorks.FeaturesStep2.Features.CreatingShipments.Title",
    DescriptionKey: "HowItWorks.FeaturesStep2.Features.CreatingShipments.Description",
    Icon: FaBoxesPacking,
  },
];

const featuresStep3 = [
  {
    NameKey: "HowItWorks.FeaturesStep3.Features.ShipmentDashboard.Title",
    DescriptionKey: "HowItWorks.FeaturesStep3.Features.ShipmentDashboard.Description",
    Icon: TbLayoutDashboard,
  },
  {
    NameKey: "HowItWorks.FeaturesStep3.Features.MonitoringPerformance.Title",
    DescriptionKey: "HowItWorks.FeaturesStep3.Features.MonitoringPerformance.Description",
    Icon: CgPerformance,
  },
  {
    NameKey: "HowItWorks.FeaturesStep3.Features.FinancialInsights.Title",
    DescriptionKey: "HowItWorks.FeaturesStep3.Features.FinancialInsights.Description",
    Icon: MdInsights,
  },
];

export default function HowItWorks() {
  const {t} = useTranslation();

  return (
    <div className="container relative">
      <div className="overflow-hidden bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl">
          <div className="mx-auto max-w-2xl lg:text-center">
            <h2 className="text-base font-semibold leading-7 text-indigo-600">{t("HowItWorks.BadgeText")}</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{t("HowItWorks.Title")}</p>
            <p className="mt-6 text-lg leading-8 text-gray-600">{t("HowItWorks.Description")}</p>
          </div>
          <div className="mx-auto mt-16 sm:mt-20 lg:mt-24">
            <div className="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-center">
              <div className="lg:pr-4">
                <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
                  <h2 className="text-base font-semibold leading-7 text-indigo-600">{t("HowItWorks.FeaturesStep1.BadgeText")}</h2>
                  <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{t("HowItWorks.FeaturesStep1.Title")}</p>
                  <p className="mt-6 text-lg leading-8 text-gray-600">{t("HowItWorks.FeaturesStep1.Description")}</p>
                  <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                    {featuresStep1.map((feature, index) => (
                      <div key={index} className="relative pl-9">
                        <dt className="inline font-semibold text-gray-900">
                          <feature.Icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                          {t(feature.NameKey)}.
                        </dt>{" "}
                        <dd className="inline">{t(feature.DescriptionKey)}</dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
              <div className="relative isolate overflow-hidden bg-white sm:mx-auto sm:max-w-2xl sm:pr-0 lg:mx-0 lg:max-w-none">
                <div className="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
                  <img src={imageStep1} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-hidden bg-white pb-12 sm:pb-12">
        <div className="mx-auto max-w-7xl">
          <div className="mx-auto mt-16 sm:mt-20 lg:mt-24">
            <div className="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-center">
              <div className="relative isolate overflow-hidden bg-white sm:mx-auto sm:max-w-2xl sm:pr-0 lg:mx-0 lg:max-w-none">
                <div className="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
                  <img src={imageStep2} alt="" />
                </div>
              </div>

              <div className="lg:pr-4 lg:ms-8">
                <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
                  <h2 className="text-base font-semibold leading-7 text-indigo-600">{t("HowItWorks.FeaturesStep2.BadgeText")}</h2>
                  <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{t("HowItWorks.FeaturesStep2.Title")}</p>
                  <p className="mt-6 text-lg leading-8 text-gray-600">{t("HowItWorks.FeaturesStep2.Description")}</p>
                  <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                    {featuresStep2.map((feature, index) => (
                      <div key={index} className="relative pl-9">
                        <dt className="inline font-semibold text-gray-900">
                          <feature.Icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                          {t(feature.NameKey)}.
                        </dt>{" "}
                        <dd className="inline">{t(feature.DescriptionKey)}</dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-hidden bg-white pb-24 sm:pb-32">
        <div className="mx-auto max-w-7xl">
          <div className="mx-auto mt-16 sm:mt-20 lg:mt-24">
            <div className="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-center">
              <div className="lg:pr-4">
                <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
                  <h2 className="text-base font-semibold leading-7 text-indigo-600">{t("HowItWorks.FeaturesStep3.BadgeText")}</h2>
                  <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{t("HowItWorks.FeaturesStep3.Title")}</p>
                  <p className="mt-6 text-lg leading-8 text-gray-600">{t("HowItWorks.FeaturesStep3.Description")}</p>
                  <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                    {featuresStep3.map((feature, index) => (
                      <div key={index} className="relative pl-9">
                        <dt className="inline font-semibold text-gray-900">
                          <feature.Icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                          {t(feature.NameKey)}.
                        </dt>{" "}
                        <dd className="inline">{t(feature.DescriptionKey)}</dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
              <div className="relative isolate overflow-hidden  sm:mx-auto sm:max-w-2xl  sm:pr-0 lg:mx-0 lg:max-w-none">
                <div className="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
                  <img src={imageStep3} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
