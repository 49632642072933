import i18n from "./i18n";

export const getPath = (path) => {
  const currentLang = i18n.language;

  if (path.startsWith("http")) {
    return path;
  }

  if (currentLang === "tr" && !path.startsWith("/tr")) {
    return `/tr${path}`;
  }

  return path;
};

export const flattenArray = (arr) => {
  return arr.reduce((acc, val) => {
    return Array.isArray(val) ? acc.concat(flattenArray(val)) : acc.concat(val);
  }, []);
};

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};
