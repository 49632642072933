import React from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {getPath} from "../utils";
import {keyFeatures} from "../data/keyFeatures";

import logoLight from "../assets/images/logo-dark.png";
import poweredBystrpie from "../assets/images/powered-by-stripe.svg";

import {footerUsefullLinks, footerCompanyLinks, social} from "../data/links";
import LanguageSelector from "./languageSelector";
import {FaCcAmex, FaCcDiscover, FaCcJcb, FaCcMastercard, FaCcVisa} from "react-icons/fa";
import {MdArrowForward} from "react-icons/md";

export default function Footer() {
  const {t} = useTranslation();
  const {t: tFeatures} = useTranslation("features");

  const filteredKeyFeatures = keyFeatures.filter((item) => item.FeaturedInFooter === true).slice(0, 7);

  return (
    <footer className="footer bg-dark-footer relative text-gray-200 dark:text-gray-200">
      <div className="container relative">
        <div className="grid grid-cols-12">
          <div className="col-span-12">
            <div className="py-[60px] px-0">
              <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                <div className="lg:col-span-4 md:col-span-12">
                  <Link to={getPath("/")} className="text-[22px] focus:outline-none">
                    <img src={logoLight} alt="" className="max-w-28" />
                  </Link>
                  <p className="mt-6 text-sm leading-6 text-gray-300">{t("Footer.Description")}</p>
                  <div className="flex items-center space-x-4 mt-3">
                    {social.map((data, index) => (
                      <Link key={index} to={t(data.Href)} target="_blank" className="text-gray-500 hover:text-gray-400">
                        <data.Icon className="h-6 w-6" />
                      </Link>
                    ))}
                    <LanguageSelector />
                  </div>
                </div>

                <div className="lg:col-span-2 md:col-span-4">
                  <h5 className="text-sm font-semibold leading-6 text-white">{t("Company")}</h5>
                  <ul className="list-none footer-list mt-6">
                    {footerCompanyLinks.map((data, index) => (
                      <li key={index} className={index === 0 ? "ms-0" : "mt-[10px] ms-0"}>
                        <Link to={getPath(t(data.Route))} className="text-sm leading-6 text-gray-300 hover:text-white">
                          {t(data.TitleKey)}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="lg:col-span-3 md:col-span-4">
                  <h5 className="text-sm font-semibold leading-6 text-white">{t("UsefullLinks")}</h5>
                  <ul className="list-none footer-list mt-6">
                    {footerUsefullLinks.map((data, index) => (
                      <li key={index} className={index === 0 ? "ms-0" : "mt-[10px] ms-0"}>
                        <Link to={getPath(t(data.Route))} className="text-sm leading-6 text-gray-300 hover:text-white">
                          {t(data.TitleKey)}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="lg:col-span-3 md:col-span-4">
                  <h5 className="text-sm font-semibold leading-6 text-white">{t("Features")}</h5>
                  <ul className="list-none footer-list mt-6">
                    {filteredKeyFeatures.map((data, index) => (
                      <li key={index} className={index === 0 ? "ms-0" : "mt-[10px] ms-0"}>
                        <Link to={getPath(`/features/${data.PageURL}`)} className="text-sm leading-6 text-gray-300 hover:text-white">
                          {tFeatures(data.TitleKey)}
                        </Link>
                      </li>
                    ))}
                    <li className="mt-[10px] ms-0">
                      <Link to={getPath(`/features`)} className="inline-flex items-center tracking-wide align-middle text-sm leading-6 text-gray-300 hover:text-white">
                        {t("SellAll")} <MdArrowForward className="text-base ms-1" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-[30px] px-0 border-t border-slate-800">
        <div className="container relative text-center">
          <div className="grid md:grid-cols-2 items-center">
            <div className="md:text-start text-center">
              <p className="text-xs leading-5 text-gray-400">
                Crafted with <i className="mdi mdi-heart text-red-600"></i> by SellThis © {new Date().getFullYear()}
              </p>
            </div>

            <ul className="list-none md:text-end text-center space-x-2 mt-6 md:mt-0">
              <li className="inline">
                <img src={poweredBystrpie} alt="" className="inline" />
              </li>
              <li className="inline">
                <FaCcAmex className="h-8 w-8 inline text-white" />
              </li>
              <li className="inline">
                <FaCcVisa className="h-8 w-8 inline text-white" />
              </li>
              <li className="inline">
                <FaCcMastercard className="h-8 w-8 inline text-white" />
              </li>
              <li className="inline">
                <FaCcDiscover className="h-8 w-8 inline text-white" />
              </li>
              <li className="inline">
                <FaCcJcb className="h-8 w-8 inline text-white" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
