import {useTranslation} from "react-i18next";
import {CheckIcon} from "@heroicons/react/20/solid";
import {pricing} from "../data/pricing";
import {classNames} from "../utils";

export default function Pricing() {
  const {t} = useTranslation();

  return (
    <div className="container relative">
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl">
          <div className="mx-auto max-w-4xl text-center">
            <h2 className="text-base font-semibold leading-7 text-indigo-600">{t("Pricing.BadgeText")}</h2>
            <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">{t("Pricing.HeadlineAltHome")}</p>
          </div>

          <p
            className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600"
            dangerouslySetInnerHTML={{
              __html: t("Pricing.HeadlineDescriptionAltHome"),
            }}></p>

          <div className="isolate mx-auto mt-16 grid max-w-md grid-cols-1 gap-y-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {pricing.tiers.map((tier, index) => (
              <div
                key={tier.Id}
                className={classNames(
                  tier.MostPopular ? "lg:z-10 lg:rounded-b-none" : "lg:mt-8",
                  index === 0 ? "lg:rounded-r-none" : "",
                  index === pricing.tiers.length - 1 ? "lg:rounded-l-none" : "",
                  "flex flex-col justify-between rounded-3xl bg-white p-8 ring-1 ring-gray-200 xl:p-10",
                )}>
                <div>
                  <div className="flex items-center justify-between gap-x-4">
                    <h3 id={tier.Id} className={classNames(tier.MostPopular ? "text-indigo-600" : "text-gray-900", "text-lg font-semibold leading-8")}>
                      {t(tier.Name)}
                    </h3>
                    {tier.MostPopular ? <p className="rounded-full bg-indigo-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-indigo-600">{t("MostPopular")}</p> : null}
                  </div>
                  <p className="mt-4 text-sm leading-6 text-gray-600">{t(tier.Description)}</p>
                  <p className="mt-6 flex items-baseline gap-x-1">
                    <span className="text-4xl font-bold tracking-tight text-gray-900">{t(tier.Price.Monthly)}</span>
                    <span className="text-sm font-semibold leading-6 text-gray-600">{t("Pricing.Frequencies.Monthly.PriceSuffix")}</span>
                  </p>
                  <ul className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                    {tier.Features.map((feature) => (
                      <li key={feature} className="flex gap-x-3">
                        <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
                        {t(feature)}
                      </li>
                    ))}
                  </ul>
                </div>
                <a
                  href={`https://app.sellthis.com/account/register?tier=${tier.Id.toLowerCase()}`}
                  aria-describedby={tier.Id}
                  className={classNames(
                    tier.MostPopular ? "bg-indigo-600 text-white shadow-sm hover:bg-indigo-500" : "text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300",
                    "mt-8 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
                  )}>
                  {t("GetStarted")}
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
