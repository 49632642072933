import React from "react";
import {useTranslation} from "react-i18next";
import image1 from "../assets/images/affiliate/smiling-woman.jpg";
import image2 from "../assets/images/affiliate/girls-scream-in-megaphone.jpg";

import {HiOutlineRocketLaunch, LiaBusinessTimeSolid, MdArrowForward, MdAutorenew, TbReportSearch} from "../assets/icons/icons";
import {Link} from "react-router-dom";
import {FaDollarSign} from "react-icons/fa6";

export default function AffiliateHero() {
  const {t} = useTranslation();

  return (
    <div className="container relative">
      <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-10 gap-[25px]">
        <div className="md:col-span-6">
          <div className="md:me-6">
            <p className="text-indigo-600 text-lg font-medium mb-4">{t("Affiliate.HeadlineDescription")}</p>
            <h4 className="font-bold lg:leading-normal leading-normal text-4xl lg:text-6xl mb-5 text-black dark:text-white">
              {t("Affiliate.Headline.Part1")}{" "}
              <span className="after:absolute after:end-0 after:start-0 after:bottom-2 after:h-1.5 after:w-auto after:bg-gradient-to-l after:to-indigo-600 after:from-red-600 relative pb-2">
                {t("Affiliate.Headline.Part2")}
              </span>{" "}
              <span className="after:absolute after:end-0 after:start-0 after:bottom-2 after:h-1.5 after:w-auto after:bg-gradient-to-l after:to-indigo-600 after:from-red-600 relative pb-2">
                {t("Affiliate.Headline.Part3")}
              </span>{" "}
              {t("Affiliate.Headline.Part4")}
            </h4>

            <ul className="list-none text-slate-600 mt-4">
              <li className="mb-1 flex items-center ms-0">
                <FaDollarSign className="text-indigo-600 text-base me-2" />
                {t("Affiliate.HeadlineBulletPoints.BulletPoint1")}
              </li>
              <li className="mb-1 flex items-center ms-0">
                <TbReportSearch className="text-indigo-600 text-base me-2" />
                {t("Affiliate.HeadlineBulletPoints.BulletPoint2")}
              </li>
              <li className="mb-1 flex items-center ms-0">
                <LiaBusinessTimeSolid className="text-indigo-600 text-base me-2" />
                {t("Affiliate.HeadlineBulletPoints.BulletPoint3")}
              </li>
              <li className="mb-1 flex items-center ms-0">
                <HiOutlineRocketLaunch className="text-indigo-600 text-base me-2" />
                {t("Affiliate.HeadlineBulletPoints.BulletPoint4")}
              </li>
              <li className="mb-1 flex items-center ms-0">
                <MdAutorenew className="text-indigo-600 text-base me-2" />
                {t("Affiliate.HeadlineBulletPoints.BulletPoint5")}
              </li>
            </ul>
            <div className="mt-6">
              <Link
                to="https://affiliates.sellthis.com/sign-up"
                target="_blank"
                className="bg-indigo-600 text-white items-center shadow-sm hover:bg-indigo-500 rounded-md px-5 py-2 text-center font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 inline-flex align-middle">
                {t("Affiliate.EnrollButton")} <MdArrowForward className="text-base ms-1" />
              </Link>
              <p className="text-slate-600 text-sm mt-3">{t("Affiliate.EnrollButtonCTA")}</p>
            </div>
          </div>
        </div>

        <div className="md:col-span-6">
          <div className="grid grid-cols-12 gap-4 items-center">
            <div className="col-span-5">
              <div className="grid grid-cols-1 gap-4">
                <img src={image2} className="shadow rounded-lg" alt="" />

                <div className="ms-auto">
                  <div className="size-28 bg-indigo-600/10 rounded-lg"></div>
                </div>
              </div>
            </div>

            <div className="col-span-7">
              <div className="grid grid-cols-1 gap-4">
                <img src={image1} className="shadow rounded-lg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
