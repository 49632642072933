import React from "react";
import {useTranslation} from "react-i18next";

import {CloudArrowUpIcon} from "@heroicons/react/20/solid";
import {IoBugOutline} from "react-icons/io5";
import {MdScience} from "react-icons/md";
import {Link} from "react-router-dom";

const steps = [
  {
    NameKey: "HowToStart.Steps.Step1.Title",
    DescriptionKey: "HowToStart.Steps.Step1.Description",
    LabelKey: "HowToStart.Steps.Step1.Label",
    Icon: IoBugOutline,
  },
  {
    NameKey: "HowToStart.Steps.Step2.Title",
    DescriptionKey: "HowToStart.Steps.Step2.Description",
    LabelKey: "HowToStart.Steps.Step2.Label",
    Icon: CloudArrowUpIcon,
  },
  {
    NameKey: "HowToStart.Steps.Step3.Title",
    DescriptionKey: "HowToStart.Steps.Step3.Description",
    LabelKey: "HowToStart.Steps.Step3.Label",
    Icon: MdScience,
  },
  {
    NameKey: "HowToStart.Steps.Step4.Title",
    DescriptionKey: "HowToStart.Steps.Step4.Description",
    LabelKey: "HowToStart.Steps.Step4.Label",
    Icon: MdScience,
  },
];

export default function HowToStart() {
  const {t} = useTranslation();

  return (
    <div className="relative bg-gray-900 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-400">{t("HowToStart.BadgeText")}</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">{t("HowToStart.Title")}</p>
          <p className="mt-6 text-lg leading-8 text-gray-300">{t("HowToStart.Description")} </p>
        </div>
        <div className="mx-auto max-w-7xl mt-16 sm:mt-20 lg:mt-24 ">
          <div className="relative mx-auto grid max-w-2xl grid-cols-1 gap-8 overflow-hidden lg:mx-0 lg:max-w-none lg:grid-cols-4">
            {steps.map((item, index) => (
              <div key={index}>
                <time className="flex items-center font-semibold leading-6 text-indigo-400">
                  <svg viewBox="0 0 4 4" aria-hidden="true" className="mr-4 h-1 w-1 flex-none">
                    <circle r={2} cx={2} cy={2} fill="currentColor" />
                  </svg>
                  {t(item.LabelKey)}
                  <div aria-hidden="true" className="absolute -ml-2 h-px w-screen -translate-x-full bg-white/20 sm:-ml-4 lg:static lg:-mr-6 lg:ml-8 lg:w-auto lg:flex-auto lg:translate-x-0" />
                </time>
                <p className="mt-6 text-lg font-semibold leading-8 tracking-tight text-white">{t(item.NameKey)}</p>
                <p className="mt-1 text-base leading-7 text-gray-300">{t(item.DescriptionKey)}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="px-6 pt-24 sm:px-6 sm:pt-32 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">{t("CTAs.ReadyToStart.Title")}</h2>
          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">{t("CTAs.ReadyToStart.Description")}</p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link
              to={"https://app.sellthis.com/account/register"}
              target="_blank"
              className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white try-for-free">
              {t("CTAs.ReadyToStart.Button")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
