import {useTranslation} from "react-i18next";
import {classNames} from "../utils";

export default function Testimonials() {
  const {t} = useTranslation();
  const {t: tTestimonials} = useTranslation("testimonials");

  const testimonials = tTestimonials("Testimonials", {returnObjects: true});
  const featuredTestimonial = tTestimonials("FeaturedTestimonial", {returnObjects: true});

  return (
    <div className="relative isolate bg-white py-24 sm:py-32 pb-32 pt-32 sm:pt-44">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <svg className="absolute inset-0 -z-10 hidden h-full w-full stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)] sm:block" aria-hidden="true">
          <defs>
            <pattern id="55d3d46d-692e-45f2-becd-d8bdc9344f45" width="200" height="200" x="50%" y="0" patternUnits="userSpaceOnUse">
              <path d="M.5 200V.5H200" fill="none"></path>
            </pattern>
          </defs>
          <svg x="50%" y="0" className="overflow-visible fill-gray-50">
            <path d="M-200.5 0h201v201h-201Z M599.5 0h201v201h-201Z M399.5 400h201v201h-201Z M-400.5 600h201v201h-201Z" strokeWidth="0"></path>
          </svg>
          <rect width="100%" height="100%" strokeWidth="0" fill="url(#55d3d46d-692e-45f2-becd-d8bdc9344f45)"></rect>
        </svg>
        <div className="absolute inset-x-0 top-1/2 -z-10 -translate-y-1/2 transform-gpu overflow-hidden opacity-30 blur-3xl" aria-hidden="true">
          <div
            className="ml-[max(50%,38rem)] aspect-[1313/771] w-[82.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div className="absolute inset-x-0 top-0 -z-10 flex transform-gpu overflow-hidden pt-32 opacity-25 blur-3xl sm:pt-40 xl:justify-end" aria-hidden="true">
          <div
            className="ml-[-22rem] aspect-[1313/771] w-[82.0625rem] flex-none origin-top-right rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] xl:ml-0 xl:mr-[calc(50%-12rem)]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div className="mx-auto max-w-7xl">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-lg font-semibold leading-8 tracking-tight text-indigo-600">{t("Testimonials.Title")}</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{t("Testimonials.Description")}</p>
          </div>
          <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 grid-rows-1 gap-8 text-sm leading-6 text-gray-900 sm:mt-20 sm:grid-cols-2 xl:mx-0 xl:max-w-none xl:grid-flow-col xl:grid-cols-4">
            <figure className="rounded-2xl bg-white shadow-lg ring-1 ring-gray-900/5 sm:col-span-2 xl:col-start-2 xl:row-end-1">
              <blockquote className="p-6 text-lg font-semibold leading-7 tracking-tight text-gray-900 sm:p-12 sm:text-xl sm:leading-8">
                <p>{`“${featuredTestimonial.Body}”`}</p>
              </blockquote>
              <figcaption className="flex flex-wrap items-center gap-x-4 gap-y-4 border-t border-gray-900/10 px-6 py-4 sm:flex-nowrap">
                {featuredTestimonial.Author.ImageUrl && <img className="h-10 w-10 flex-none rounded-full bg-gray-50" src={featuredTestimonial.Author.ImageUrl} alt="" />}
                <div className="flex-auto">
                  <div className="font-semibold">{featuredTestimonial.Author.Name}</div>
                  {featuredTestimonial.Author.Position && <div className="text-gray-600">{featuredTestimonial.Author.Position}</div>}
                </div>
                {featuredTestimonial.Author.LogoUrl && <img className="h-10 w-auto flex-none" src={featuredTestimonial.Author.LogoUrl} alt="" />}
              </figcaption>
            </figure>
            {testimonials.map((columnGroup, columnGroupIdx) => (
              <div key={columnGroupIdx} className="space-y-8 xl:contents xl:space-y-0">
                {columnGroup.map((column, columnIdx) => (
                  <div
                    key={columnIdx}
                    className={classNames(
                      (columnGroupIdx === 0 && columnIdx === 0) || (columnGroupIdx === testimonials.length - 1 && columnIdx === columnGroup.length - 1) ? "xl:row-span-2" : "xl:row-start-1",
                      "space-y-8",
                    )}>
                    {column.map((testimonial, index) => (
                      <figure key={index} className="rounded-2xl bg-white p-6 shadow-lg ring-1 ring-gray-900/5">
                        <blockquote className="text-gray-900">
                          <p>{`“${testimonial.Body}”`}</p>
                        </blockquote>
                        <figcaption className="mt-6 flex items-center gap-x-4">
                          {testimonial.Author.ImageUrl && <img className="h-10 w-10 rounded-full bg-gray-50" src={testimonial.Author.ImageUrl} alt="" />}
                          <div>
                            <div className="font-semibold">{testimonial.Author.Name}</div>
                            <div className="text-gray-600">{testimonial.Author.Position}</div>
                          </div>
                        </figcaption>
                      </figure>
                    ))}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
