import React from "react";
import {useTranslation} from "react-i18next";
import {keyFeatures} from "../data/keyFeatures";
import {Link} from "react-router-dom";
import {getPath} from "../utils";
import {MdArrowForward} from "react-icons/md";

export default function KeyFeatures() {
  const {t} = useTranslation();
  const {t: tFeatures} = useTranslation("features");

  const filteredKeyFeatures = keyFeatures.filter((item) => item.FeaturedInHomePage === true).slice(0, 9);

  return (
    <div className="container relative">
      <div className="py-24 sm:py-32">
        <div className="mx-auto max-w-2xl lg:text-center">
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{t("HomeKeyFeatures.Title")}</p>
          <p className="mt-6 text-lg leading-8 text-gray-600">{t("HomeKeyFeatures.Description")}</p>
        </div>

        <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px] mt-8mx-auto mt-16 sm:mt-20 lg:mt-24">
          {filteredKeyFeatures.map((item, index) => {
            const Icon = item.Icon;
            return (
              <div className="lg:col-span-4 md:col-span-6" key={index}>
                <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-3 rounded-md bg-white dark:bg-slate-900">
                  <div className="flex items-center justify-center h-[45px] min-w-[45px] -rotate-45 bg-gradient-to-r from-transparent to-indigo-600/10 text-indigo-600 text-center rounded-full me-3">
                    <Icon className="size-5 rotate-45" />
                  </div>
                  <div className="flex-1">
                    <h4 className="mb-0 font-medium">{tFeatures(item.TitleKey)}</h4>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="grid grid-cols-1 justify-center">
          <div className="mt-6 text-center">
            <Link
              to={getPath("/features")}
              className="relative inline-flex items-center tracking-wide align-middle text-base text-center border-none font-semibold leading-6 text-indigo-600 hover:text-indigo-400">
              {t("SellAll")} <MdArrowForward className="text-base ms-1" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
