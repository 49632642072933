import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";

import config from "./config";

import translationEN from "./locales/en/translation.json";
import featuresEN from "./locales/en/features.json";
import testimonialsEN from "./locales/en/testimonials.json";

import translationTR from "./locales/tr/translation.json";
import featuresTR from "./locales/tr/features.json";
import testimonialsTR from "./locales/tr/testimonials.json";

// the translations
const resources = {
  en: {
    translation: translationEN,
    features: featuresEN,
    testimonials: testimonialsEN,
  },
  tr: {
    translation: translationTR,
    features: featuresTR,
    testimonials: testimonialsTR,
  },
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: config.defaults.lang,
    keySeparator: ".",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
