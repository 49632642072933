export const faqsData = [
  {
    Id: 1,
    Category: "GENERAL",
    FeaturedInPricing: false,
  },
  {
    Id: 14,
    Category: "GENERAL",
    FeaturedInHomePage: true,
  },
  {
    Id: 19,
    Category: "GENERAL",
    FeaturedInHomePage: true,
  },
  {
    Id: 15,
    Category: "GENERAL",
    FeaturedInHomePage: true,
  },
  {
    Id: 16,
    Category: "GENERAL",
    FeaturedInHomePage: true,
  },
  {
    Id: 2,
    Category: "GENERAL",
    FeaturedInPricing: true,
  },
  {
    Id: 3,
    Category: "GENERAL",
    FeaturedInPricing: false,
  },
  {
    Id: 4,
    Category: "GENERAL",
    FeaturedInPricing: true,
  },
  {
    Id: 5,
    Category: "GENERAL",
    FeaturedInPricing: false,
  },
  {
    Id: 6,
    Category: "GENERAL",
    FeaturedInPricing: true,
  },
  {
    Id: 7,
    Category: "GENERAL",
    FeaturedInPricing: false,
  },
  {
    Id: 8,
    Category: "GENERAL",
    FeaturedInPricing: true,
  },
  {
    Id: 9,
    Category: "GENERAL",
    FeaturedInPricing: false,
  },
  {
    Id: 10,
    Category: "GENERAL",
    FeaturedInPricing: false,
  },
  {
    Id: 11,
    Category: "GENERAL",
    FeaturedInPricing: false,
  },
  {
    Id: 12,
    Category: "GENERAL",
    FeaturedInPricing: false,
  },
  {
    Id: 13,
    Category: "GENERAL",
    FeaturedInPricing: true,
  },
  {
    Id: 17,
    Category: "GENERAL",
  },
  {
    Id: 18,
    Category: "GENERAL",
    FeaturedInHomePage: true,
  },
  {
    Id: 1,
    Category: "PAYMENTS",
    FeaturedInPricing: false,
  },
  {
    Id: 2,
    Category: "PAYMENTS",
    FeaturedInPricing: true,
  },
  {
    Id: 3,
    Category: "PAYMENTS",
    FeaturedInPricing: true,
  },
  {
    Id: 4,
    Category: "PAYMENTS",
    FeaturedInPricing: false,
  },
  {
    Id: 5,
    Category: "PAYMENTS",
    FeaturedInPricing: true,
  },
  {
    Id: 6,
    Category: "PAYMENTS",
    FeaturedInPricing: false,
  },
  {
    Id: 8,
    Category: "PAYMENTS",
    FeaturedInPricing: false,
  },
  {
    Id: 9,
    Category: "PAYMENTS",
    FeaturedInPricing: false,
  },
  {
    Id: 10,
    Category: "PAYMENTS",
    FeaturedInPricing: false,
  },
  {
    Id: 11,
    Category: "PAYMENTS",
    FeaturedInPricing: false,
  },
  {
    Id: 12,
    Category: "PAYMENTS",
    FeaturedInPricing: true,
  },
  {
    Id: 13,
    Category: "PAYMENTS",
    FeaturedInPricing: false,
  },
  {
    Id: 1,
    Category: "SUPPORT",
    FeaturedInPricing: false,
  },
  {
    Id: 2,
    Category: "SUPPORT",
    FeaturedInPricing: false,
  },
  {
    Id: 3,
    Category: "SUPPORT",
    FeaturedInPricing: false,
  },
  {
    Id: 4,
    Category: "SUPPORT",
    FeaturedInPricing: false,
  },
  {
    Id: 5,
    Category: "SUPPORT",
    FeaturedInPricing: false,
  },
  {
    Id: 6,
    Category: "SUPPORT",
    FeaturedInPricing: false,
  },
  {
    Id: 8,
    Category: "SUPPORT",
    FeaturedInPricing: false,
  },
  {
    Id: 9,
    Category: "SUPPORT",
    FeaturedInPricing: false,
  },
  {
    Id: 10,
    Category: "SUPPORT",
    FeaturedInPricing: false,
  },
  {
    Id: 11,
    Category: "SUPPORT",
    FeaturedInPricing: false,
  },
  {
    Id: 15,
    Category: "SUPPORT",
    FeaturedInPricing: false,
  },
  {
    Id: 14,
    Category: "SUPPORT",
    FeaturedInPricing: false,
  },
];
