import React, {useState} from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Navbar from "../component/navbar";
import Footer from "../component/footer";
import {social} from "../data/links";
import {Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from "yup";
import {sendContactForm} from "../services/contactService"; // Import the service

import hero from "../assets/images/contact-us-hero.svg";

import {MdArrowOutward, FaRegEnvelope, FaLifeRing} from "../assets/icons/icons";
import {BuildingOffice2Icon, EnvelopeIcon, PhoneIcon, UserIcon} from "@heroicons/react/24/outline";

export default function ContactUs() {
  const {t, i18n} = useTranslation();

  const [formSubmitted, setFormSubmitted] = useState(false); // State to control form visibility
  const [statusMessage, setStatusMessage] = useState(""); // State to display the status message

  document.title = t("PageTitles.ContactUs");

  // Validation schema for form fields
  const validationSchema = Yup.object({
    firstName: Yup.string().required(t("ContactUs.ContactForm.Validation.Required")),
    lastName: Yup.string().required(t("ContactUs.ContactForm.Validation.Required")),
    email: Yup.string().email(t("ContactUs.ContactForm.Validation.InvalidEmail")).required(t("ContactUs.ContactForm.Validation.Required")),
    phoneNumber: Yup.string().required(t("ContactUs.ContactForm.Validation.Required")),
    message: Yup.string().required(t("ContactUs.ContactForm.Validation.Required")),
  });

  return (
    <>
      <Navbar />

      <section className="relative table w-full py-20 lg:py-24 bg-indigo-600/5 overflow-hidden">
        <div className="container relative">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-10 gap-[30px] relative">
            <div className="md:col-span-7">
              <h4 className="font-bold lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 text-black dark:text-white">
                {t("ContactUs.Headline.Part1")} <span className="text-indigo-600">{t("ContactUs.Headline.Part2")}</span>
              </h4>
              <p className="text-slate-400 text-lg max-w-xl">{t("ContactUs.HeadlineDescription")}</p>
            </div>

            <div className="md:col-span-5">
              <div className="relative">
                <img src={hero} alt="" className="bg-transparent" />
                <div className="absolute bottom-2/4 translate-y-2/4 start-0 end-0 text-center"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px]">
            <div className="text-center px-6 mt-6">
              <div className="size-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                <FaRegEnvelope className="size-7" />
              </div>

              <div className="content mt-7">
                <h5 className="title h5 text-xl font-medium">{t("ContactUs.Features.Email.Title")}</h5>
                <p className="text-gray-600 mt-3">{t("ContactUs.Features.Email.Description")}</p>

                <div className="mt-5">
                  <Link
                    to="mailto:support@sellthis.com"
                    className="relative inline-flex items-center tracking-wide align-middle text-base text-center border-none font-semibold leading-6 text-indigo-600 hover:text-indigo-400">
                    support@sellthis.com
                  </Link>
                </div>
              </div>
            </div>
            <div className="text-center px-6 mt-6">
              <div className="size-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                <FaLifeRing className="size-7" />
              </div>

              <div className="content mt-7">
                <h5 className="title h5 text-xl font-medium">{t("ContactUs.Features.HelpCenter.Title")}</h5>
                <p className="text-gray-600 mt-3">{t("ContactUs.Features.HelpCenter.Description")}</p>

                <div className="mt-5">
                  <Link
                    to={`https://help.sellthis.com/${i18n.language}`}
                    target="_blank"
                    className="relative inline-flex items-center tracking-wide align-middle text-base text-center border-none font-semibold leading-6 text-indigo-600 hover:text-indigo-400">
                    help.sellthis.com <MdArrowOutward className="text-base" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="text-center px-6 mt-6">
              <div className="size-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                <FaRegEnvelope className="size-7" />
              </div>

              <div className="content mt-7">
                <h5 className="title h5 text-xl font-medium">{t("ContactUs.Features.SocialMedia.Title")}</h5>
                <p className="text-gray-600 mt-3">{t("ContactUs.Features.SocialMedia.Description")}</p>

                <div className="mt-5 flex space-x-4 justify-center">
                  {social.map((data, index) => (
                    <Link key={index} to={t(data.Href)} target="_blank" className="text-gray-700 hover:text-gray-500">
                      <data.Icon className="h-6 w-6" />
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container relative md:mt-24 mt-16">
          <div className="relative isolate bg-white">
            <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
              <div className="relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-18">
                <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                  <h2 className="text-3xl font-bold tracking-tight text-gray-900">{t("ContactUs.ContactForm.Title")}</h2>
                  <p className="mt-6 text-lg leading-8 text-gray-600">{t("ContactUs.ContactForm.HeadLine")}</p>
                  <dl className="mt-10 space-y-4 text-base leading-7 text-gray-600">
                    <div className="flex gap-x-4">
                      <dt className="flex-none">
                        <span className="sr-only">Address</span>
                        <BuildingOffice2Icon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                      </dt>
                      <dd>
                        896 S State St, Unit #480
                        <br />
                        Dover, DE 19901
                      </dd>
                    </div>
                    <div className="flex gap-x-4">
                      <dt className="flex-none">
                        <span className="sr-only">Telephone</span>
                        <PhoneIcon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                      </dt>
                      <dd>
                        <a className="hover:text-gray-900" href="tel:+1 (201) 365-4546">
                          +1 (201) 365-4546
                        </a>
                      </dd>
                    </div>
                    <div className="flex gap-x-4">
                      <dt className="flex-none">
                        <span className="sr-only">Email</span>
                        <EnvelopeIcon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                      </dt>
                      <dd>
                        <a className="hover:text-gray-900" href="mailto:support@sellthis.com">
                          support@sellthis.com
                        </a>
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
              <div className="px-6 pb-24 pt-20 sm:pb-20 lg:px-8 lg:py-18">
                {formSubmitted && <div className="mb-6 text-center text-lg text-green-600">{statusMessage}</div>}

                {!formSubmitted && (
                  <Formik
                    initialValues={{
                      firstName: "",
                      lastName: "",
                      email: "",
                      phoneNumber: "",
                      message: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (values, {setSubmitting, setStatus}) => {
                      setSubmitting(true);
                      const result = await sendContactForm(values);
                      setStatus(result);
                      if (result.status === true) {
                        setFormSubmitted(true);
                        setStatusMessage(t("ContactUs.ContactForm.SubmitSuccess"));
                      } else {
                        setStatusMessage(t("ContactUs.ContactForm.SubmitError"));
                      }
                      setSubmitting(false);
                    }}>
                    {({isSubmitting}) => (
                      <Form className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
                        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                          <div>
                            <label htmlFor="firstName" className="block text-sm font-semibold leading-6 text-gray-900">
                              {t("ContactUs.ContactForm.FormElements.FirstName")}
                            </label>
                            <div className="relative mt-2.5">
                              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <UserIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                              </div>
                              <Field
                                type="text"
                                name="firstName"
                                id="firstName"
                                className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                            <ErrorMessage name="firstName" component="div" className="text-red-500 text-sm mt-1" />
                          </div>

                          <div>
                            <label htmlFor="lastName" className="block text-sm font-semibold leading-6 text-gray-900">
                              {t("ContactUs.ContactForm.FormElements.LastName")}
                            </label>
                            <div className="relative mt-2.5">
                              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <UserIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                              </div>
                              <Field
                                type="text"
                                name="lastName"
                                id="lastName"
                                className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                            <ErrorMessage name="lastName" component="div" className="text-red-500 text-sm mt-1" />
                          </div>

                          <div className="sm:col-span-2">
                            <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
                              {t("ContactUs.ContactForm.FormElements.Email")}
                            </label>
                            <div className="mt-2.5 relative">
                              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <EnvelopeIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                              </div>
                              <Field
                                type="email"
                                name="email"
                                id="email"
                                className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                            <ErrorMessage name="email" component="div" className="text-red-500 text-sm mt-1" />
                          </div>

                          <div className="sm:col-span-2">
                            <label htmlFor="phoneNumber" className="block text-sm font-semibold leading-6 text-gray-900">
                              {t("ContactUs.ContactForm.FormElements.PhoneNumber")}
                            </label>
                            <div className="relative mt-2.5">
                              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <PhoneIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                              </div>
                              <Field
                                type="tel"
                                name="phoneNumber"
                                id="phoneNumber"
                                className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                            <ErrorMessage name="phoneNumber" component="div" className="text-red-500 text-sm mt-1" />
                          </div>

                          <div className="sm:col-span-2">
                            <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900">
                              {t("ContactUs.ContactForm.FormElements.Message")}
                            </label>
                            <div className="mt-2.5">
                              <Field
                                name="message"
                                id="message"
                                as="textarea"
                                rows={4}
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                            <ErrorMessage name="message" component="div" className="text-red-500 text-sm mt-1" />
                          </div>
                        </div>
                        <div className="mt-8 flex justify-end">
                          <button
                            type="submit"
                            className={`rounded-md ${isSubmitting ? "bg-indigo-500" : "bg-indigo-600"}  px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                            disabled={isSubmitting}>
                            {isSubmitting ? (
                              <svg
                                aria-hidden="true"
                                role="status"
                                className="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                  fill="currentColor"></path>
                                <path
                                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                  fill="#1C64F2"></path>
                              </svg>
                            ) : null}
                            {t("ContactUs.ContactForm.SubmitButton")}
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
