import React from "react";
import {useTranslation} from "react-i18next";
import Navbar from "../component/navbar";
import Footer from "../component/footer";
import aboutUsMain from "../assets/images/about-us.jpg";
import aboutUsOurVision from "../assets/images/our-vision.jpeg";
import GetInTouch from "../component/getInTouch";

export default function AboutUs() {
  const {t} = useTranslation();

  document.title = t("PageTitles.AboutUs");

  const stats = [
    {NameKey: "AboutUs.OurMission.Stats.Stat1", Value: "2+ million"},
    {NameKey: "AboutUs.OurMission.Stats.Stat2", Value: "22%"},
    {NameKey: "AboutUs.OurMission.Stats.Stat3", Value: "245+"},
  ];

  const impactNumbersStats = {
    ProductsLaunched: "2,650+",
    Revenue: "$234k",
    Profit: "$52k",
  };

  return (
    <>
      <Navbar />

      <section className="relative table w-full py-20 lg:py-24 overflow-hidden">
        <svg aria-hidden="true" className="absolute left-1 -z-10 h-[64rem] w-[175.5rem] -translate-x-1/2 stroke-gray-900/10 [mask-image:radial-gradient(64rem_64rem_at_111.5rem_0%,white,transparent)]">
          <defs>
            <pattern id="e87443c8-56e4-4c20-9111-55b82fa704e3" width={200} height={200} patternUnits="userSpaceOnUse">
              <path d="M0.5 0V200M200 0.5L0 0.499983" />
            </pattern>
          </defs>
          <rect fill="url(#e87443c8-56e4-4c20-9111-55b82fa704e3)" width="100%" height="100%" strokeWidth={0} />
        </svg>
        <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div className="container relative">
          <div className="mt-32 overflow-hidden sm:mt-40">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
              <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8 lg:items-center">
                <div className="lg:col-end-1 lg:w-full lg:max-w-lg">
                  <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{t("AboutUs.Title")}</h2>
                  <p className="mt-6 text-xl leading-8 text-gray-600">{t("AboutUs.Description")}</p>
                  <p className="mt-6 text-base leading-7 text-gray-600">{t("AboutUs.Description2")}</p>
                </div>
                <div className="flex flex-wrap">
                  <div className="w-0 flex-auto shadow-lg lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
                    <img src={aboutUsMain} className="aspect-[8/6] w-[35rem] max-w-none rounded-2xl bg-gray-50 object-cover" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-32 overflow-hidden sm:mt-40">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{t("AboutUs.OurMission.Title")}</h2>
              <div className="mt-6 flex flex-col gap-x-8 gap-y-20 lg:flex-row">
                <div className="lg:w-full lg:max-w-2xl lg:flex-auto">
                  <p className="text-xl leading-8 text-gray-600">{t("AboutUs.OurMission.Description")}</p>
                  <div className="mt-10 max-w-xl text-base leading-7 text-gray-700">
                    <p>{t("AboutUs.OurMission.Description2")}</p>
                    <p className="mt-10">{t("AboutUs.OurMission.Description3")}</p>
                  </div>
                </div>
                <div className="lg:flex lg:flex-auto lg:justify-center">
                  <dl className="w-64 space-y-8 xl:w-80">
                    {stats.map((stat, index) => (
                      <div key={index} className="flex flex-col-reverse gap-y-4">
                        <dt className="text-base leading-7 text-gray-600">{t(stat.NameKey)}</dt>
                        <dd className="text-5xl font-semibold tracking-tight text-gray-900">{stat.Value}</dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-32 overflow-hidden sm:mt-40">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
              <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8 lg:items-center">
                <div className="lg:col-end-1 lg:w-full lg:max-w-lg">
                  <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{t("AboutUs.OurVision.Title")}</h2>
                  <p className="mt-6 text-xl leading-8 text-gray-600">{t("AboutUs.OurVision.Description")}</p>
                </div>
                <div className="flex flex-wrap">
                  <div className="w-0 flex-auto shadow-lg lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
                    <img src={aboutUsOurVision} className="aspect-[10/7] w-[35rem] max-w-none rounded-2xl bg-gray-50 object-cover" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-32 overflow-hidden sm:mt-40">
            <div className="mx-auto max-w-2xl lg:mx-0">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{t("AboutUs.ImpactInNumbers.Title")}</h2>
              <p className="mt-6 text-base leading-7 text-gray-600">{t("AboutUs.ImpactInNumbers.Description")}</p>
            </div>
            <div className="mx-auto mt-16 flex max-w-2xl flex-col gap-8 lg:mx-0 lg:mt-20 lg:max-w-none lg:flex-row lg:items-end">
              <div className="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-gray-50 p-8 sm:w-3/4 sm:max-w-md sm:flex-row-reverse sm:items-end lg:w-72 lg:max-w-none lg:flex-none lg:flex-col lg:items-start">
                <p className="flex-none text-3xl font-bold tracking-tight text-gray-900">{impactNumbersStats.ProductsLaunched}</p>
                <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
                  <p className="text-lg font-semibold tracking-tight text-gray-900">{t("AboutUs.ImpactInNumbers.Stats.Stat1.Title")}</p>
                  <p className="mt-2 text-base leading-7 text-gray-600">{t("AboutUs.ImpactInNumbers.Stats.Stat1.Description")}</p>
                </div>
              </div>
              <div className="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-gray-900 p-8 sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-sm lg:flex-auto lg:flex-col lg:items-start lg:gap-y-44">
                <p className="flex-none text-3xl font-bold tracking-tight text-white">{impactNumbersStats.Revenue}</p>
                <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
                  <p className="text-lg font-semibold tracking-tight text-white">{t("AboutUs.ImpactInNumbers.Stats.Stat2.Title")}</p>
                  <p className="mt-2 text-base leading-7 text-gray-400">{t("AboutUs.ImpactInNumbers.Stats.Stat2.Description", {revenue: impactNumbersStats.Revenue})}</p>
                </div>
              </div>
              <div className="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-indigo-600 p-8 sm:w-11/12 sm:max-w-xl sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-none lg:flex-auto lg:flex-col lg:items-start lg:gap-y-28">
                <p className="flex-none text-3xl font-bold tracking-tight text-white">{impactNumbersStats.Profit}</p>
                <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
                  <p className="text-lg font-semibold tracking-tight text-white">{t("AboutUs.ImpactInNumbers.Stats.Stat3.Title")}</p>
                  <p className="mt-2 text-base leading-7 text-indigo-200">{t("AboutUs.ImpactInNumbers.Stats.Stat3.Description", {profit: impactNumbersStats.Profit})}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <GetInTouch />
      </section>

      <Footer />
    </>
  );
}
