import React from "react";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Navbar from "../component/navbar";
import Footer from "../component/footer";
import {keyFeatures} from "../data/keyFeatures";

import GetInTouch from "../component/getInTouch";
import {classNames} from "../utils";
import {CheckIcon} from "@heroicons/react/20/solid";

export default function FeatureDetail() {
  const {featureId} = useParams();
  const {t} = useTranslation();
  const {t: tFeatures} = useTranslation("features");

  const featureDetail = keyFeatures.filter((item) => item.PageURL === featureId)[0];
  const data = tFeatures(`Features.${featureDetail.Key}`, {returnObjects: true});

  const images = require.context("../assets/images/features", true, /\.(png|jpe?g|svg)$/);

  const loadImage = (imagePath) => {
    try {
      const imageName = imagePath.replace("../assets/images/features/", "");
      return images(`./${imageName}`);
    } catch (error) {
      console.error(`Image not found: ${imagePath}`);
      return null;
    }
  };

  document.title = `${data.Title} - ${t("PageTitles.Features")}`;

  return (
    <>
      <Navbar />

      <section className="relative md:py-24 py-16">
        <div className="px-6 lg:px-8">
          <svg className="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]" aria-hidden="true">
            <defs>
              <pattern id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84" width="200" height="200" x="50%" y="-1" patternUnits="userSpaceOnUse">
                <path d="M.5 200V.5H200" fill="none"></path>
              </pattern>
            </defs>
            <svg x="50%" y="-1" className="overflow-visible fill-gray-50">
              <path d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z" strokeWidth="0"></path>
            </svg>
            <rect width="100%" height="100%" strokeWidth="0" fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)"></rect>
          </svg>
          <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
            <div
              className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-15 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
          <div className="overflow-hidden py-24 sm:py-32">
            <div className="mx-auto max-w-7xl">
              <div className="mx-auto max-w-2xl lg:text-center">
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{data.Title}</p>
                <p className="mt-6 text-lg leading-8 text-gray-600">{t(data.Description)}</p>
              </div>
              <div className="mx-auto mt-16 sm:mt-20 lg:mt-24">
                {data.Sections &&
                  data.Sections.map((section, index) => {
                    const imagePath = loadImage(section.Image);
                    return (
                      <div key={index} className="overflow-hidden bg-white pb-12 sm:pb-12">
                        <div className="mx-auto max-w-7xl">
                          <div className="mx-auto mt-16 sm:mt-20 lg:mt-24">
                            <div className="grid grid-cols-1 gap-x-12 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-center">
                              <div className={classNames(index % 2 === 0 ? "" : "order-last", "")}>
                                <h2 className="text-base font-semibold leading-7 text-indigo-600">{section.BadgeText}</h2>
                                <p className="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{section.Title}</p>
                                <p
                                  className="mt-6 text-lg leading-8 text-gray-600"
                                  dangerouslySetInnerHTML={{
                                    __html: section.Content,
                                  }}></p>

                                {section.BulletPoints && (
                                  <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                                    {section.BulletPoints.map((bullet, bulletIndex) => (
                                      <div key={bulletIndex} className="relative pl-9">
                                        <dt className="inline font-semibold text-gray-900">
                                          <CheckIcon aria-hidden="true" className="absolute left-0 top-1 h-5 w-5 text-indigo-500" />
                                          {bullet.Title}.
                                        </dt>{" "}
                                        <dd className="inline"> {bullet.Description}</dd>
                                      </div>
                                    ))}
                                  </dl>
                                )}
                              </div>
                              <div className="relative isolate overflow-hidden bg-white sm:mx-auto sm:max-w-2xl sm:pr-0 lg:mx-0 lg:max-w-none">
                                <div className="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
                                  {imagePath && <img src={imagePath} alt={section.Title} className="w-full h-auto max-w-none rounded-tl-xl bg-gray-800" />}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>

          <GetInTouch />
        </div>
      </section>

      <Footer />
    </>
  );
}
