import React from "react";
import {useTranslation} from "react-i18next";
import Navbar from "../component/navbar";
import Footer from "../component/footer";
import AffiliateHero from "../component/affiliateHero";

import imageInfluencers from "../assets/images/affiliate/influencers.svg";
import imageEducators from "../assets/images/affiliate/educators.svg";
import imageBloggers from "../assets/images/affiliate/bloggers.svg";
import imageAnyone from "../assets/images/affiliate/anyone.svg";

import * as Icon from "react-feather";
import {FaLifeRing, BsCurrencyDollar, MdDashboard} from "../assets/icons/icons";

import "../../node_modules/react-modal-video/css/modal-video.css";
import GetInTouch from "../component/getInTouch";

const features = [
  {
    NameKey: "Affiliate.WhyToJoinAffiliate.Features.OnTimePayouts.Title",
    DescriptionKey: "Affiliate.WhyToJoinAffiliate.Features.OnTimePayouts.Description",
    Icon: BsCurrencyDollar,
  },
  {
    NameKey: "Affiliate.WhyToJoinAffiliate.Features.AdvancedControlPanel.Title",
    DescriptionKey: "Affiliate.WhyToJoinAffiliate.Features.AdvancedControlPanel.Description",
    Icon: MdDashboard,
  },
  {
    NameKey: "Affiliate.WhyToJoinAffiliate.Features.DedicatedSupport.Title",
    DescriptionKey: "Affiliate.WhyToJoinAffiliate.Features.DedicatedSupport.Description",
    Icon: FaLifeRing,
  },
];

export default function Affiliate() {
  const {t} = useTranslation();

  document.title = t("PageTitles.Affiliate");

  return (
    <>
      <Navbar />
      <section className="relative table w-full py-36 lg:py-40">
        <AffiliateHero />
      </section>

      <section className="relative py-24 sm:py-32 bg-gray-50 dark:bg-slate-800">
        <div className=" container relative">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:text-center">
              <h2 className="text-base font-semibold leading-7 text-indigo-600">{t("Affiliate.WhyToJoinAffiliate.BadgeText")}</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{t("Affiliate.WhyToJoinAffiliate.Title")}</p>
              <p className="mt-6 text-lg leading-8 text-gray-600">{t("Affiliate.WhyToJoinAffiliate.Description")}</p>
            </div>
            <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
              <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                {features.map((feature, index) => (
                  <div key={index} className="flex flex-col">
                    <dt className="text-base font-semibold leading-7 text-gray-900">
                      <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                        <feature.Icon aria-hidden="true" className="h-6 w-6 text-white" />
                      </div>
                      {t(feature.NameKey)}
                    </dt>
                    <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
                      <p className="flex-auto">{t(feature.DescriptionKey)}</p>
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </section>

      <section className="relative py-24 sm:py-32 overflow-hidden">
        <div className="container">
          <div className="mx-auto max-w-2xl lg:text-center px-6">
            <p className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{t("Affiliate.WhoCanJoinAffiliate.Title")}</p>
            <p className="mt-6 text-lg leading-8 text-gray-600">{t("Affiliate.WhoCanJoinAffiliate.Description")}</p>
          </div>

          <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-[30px] mt-16">
            <div className="group relative lg:px-6 transition-all duration-500 ease-in-out rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center">
              <div className="relative overflow-hidden text-transparent -m-3">
                <Icon.Hexagon className="size-32 fill-indigo-600/5 mx-auto"></Icon.Hexagon>
                <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-indigo-600 rounded-xl transition-all duration-500 ease-in-out text-4xl flex align-middle justify-center items-center">
                  <img src={imageInfluencers} className="size-14" alt="" />
                </div>
              </div>

              <div className="mt-6">
                <span className="text-xl font-medium transition-all duration-500 ease-in-out">{t("Affiliate.WhoCanJoinAffiliate.Features.Influencers.Title")}</span>
                <p className="text-slate-600 transition-all duration-500 ease-in-out mt-3">{t("Affiliate.WhoCanJoinAffiliate.Features.Influencers.Description")}</p>
              </div>
            </div>

            <div className="group relative lg:px-6 transition-all duration-500 ease-in-out rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center">
              <div className="relative overflow-hidden text-transparent -m-3">
                <Icon.Hexagon className="size-32 fill-indigo-600/5 mx-auto"></Icon.Hexagon>
                <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-indigo-600 rounded-xl transition-all duration-500 ease-in-out text-4xl flex align-middle justify-center items-center">
                  <img src={imageEducators} className="size-14" alt="" />
                </div>
              </div>

              <div className="mt-6">
                <span className="text-xl font-medium transition-all duration-500 ease-in-out">{t("Affiliate.WhoCanJoinAffiliate.Features.Educators.Title")}</span>
                <p className="text-slate-600 transition-all duration-500 ease-in-out mt-3">{t("Affiliate.WhoCanJoinAffiliate.Features.Educators.Description")}</p>
              </div>
            </div>

            <div className="group relative lg:px-6 transition-all duration-500 ease-in-out rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center">
              <div className="relative overflow-hidden text-transparent -m-3">
                <Icon.Hexagon className="size-32 fill-indigo-600/5 mx-auto"></Icon.Hexagon>
                <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-indigo-600 rounded-xl transition-all duration-500 ease-in-out text-4xl flex align-middle justify-center items-center">
                  <img src={imageBloggers} className="size-14" alt="" />
                </div>
              </div>

              <div className="mt-6">
                <span className="text-xl font-medium transition-all duration-500 ease-in-out">{t("Affiliate.WhoCanJoinAffiliate.Features.Bloggers.Title")}</span>
                <p className="text-slate-600 transition-all duration-500 ease-in-out mt-3">{t("Affiliate.WhoCanJoinAffiliate.Features.Bloggers.Description")} </p>
              </div>
            </div>

            <div className="group relative lg:px-6 transition-all duration-500 ease-in-out rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center">
              <div className="relative overflow-hidden text-transparent -m-3">
                <Icon.Hexagon className="size-32 fill-indigo-600/5 mx-auto"></Icon.Hexagon>
                <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-indigo-600 rounded-xl transition-all duration-500 ease-in-out text-4xl flex align-middle justify-center items-center">
                  <img src={imageAnyone} className="size-14" alt="" />
                </div>
              </div>

              <div className="mt-6">
                <span className="text-xl font-medium transition-all duration-500 ease-in-out">{t("Affiliate.WhoCanJoinAffiliate.Features.Anyone.Title")}</span>
                <p className="text-slate-600 transition-all duration-500 ease-in-out mt-3">{t("Affiliate.WhoCanJoinAffiliate.Features.Anyone.Description")} </p>
              </div>
            </div>
          </div>
        </div>

        <GetInTouch title={false} />
      </section>

      <Footer />
    </>
  );
}
