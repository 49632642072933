import React from "react";
import {useTranslation} from "react-i18next";
import {BsDatabaseFillCheck} from "react-icons/bs";
import {FiLifeBuoy} from "react-icons/fi";
import {ArrowPathIcon, LockClosedIcon, ChartBarIcon, CursorArrowRaysIcon} from "@heroicons/react/20/solid";

const features = [
  {
    NameKey: "WhyChooseUs.Features.DataAccuracy.Title",
    DescriptionKey: "WhyChooseUs.Features.DataAccuracy.Description",
    Icon: BsDatabaseFillCheck,
  },
  {
    NameKey: "WhyChooseUs.Features.EaseOfUse.Title",
    DescriptionKey: "WhyChooseUs.Features.EaseOfUse.Description",
    Icon: CursorArrowRaysIcon,
  },
  {
    NameKey: "WhyChooseUs.Features.ComprehensiveSupport.Title",
    DescriptionKey: "WhyChooseUs.Features.ComprehensiveSupport.Description",
    Icon: FiLifeBuoy,
  },
  {
    NameKey: "WhyChooseUs.Features.SecureAndReliable.Title",
    DescriptionKey: "WhyChooseUs.Features.SecureAndReliable.Description",
    Icon: LockClosedIcon,
  },
  {
    NameKey: "WhyChooseUs.Features.ProvenResults.Title",
    DescriptionKey: "WhyChooseUs.Features.ProvenResults.Description",
    Icon: ChartBarIcon,
  },
  {
    NameKey: "WhyChooseUs.Features.ContinuousImprovement.Title",
    DescriptionKey: "WhyChooseUs.Features.ContinuousImprovement.Description",
    Icon: ArrowPathIcon,
  },
];

export default function WhyChooseUs() {
  const {t} = useTranslation();

  return (
    <div className="container relative">
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl">
          <div className="mx-auto max-w-2xl lg:text-center">
            <h2 className="text-base font-semibold leading-7 text-indigo-600">{t("WhyChooseUs.BadgeText")}</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{t("WhyChooseUs.Title")}</p>
            <p className="mt-6 text-lg leading-8 text-gray-600">{t("WhyChooseUs.Description")}</p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
              {features.map((feature, index) => (
                <div key={index} className="relative pl-16">
                  <dt className="text-base font-semibold leading-7 text-gray-900">
                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                      <feature.Icon aria-hidden="true" className="h-6 w-6 text-white" />
                    </div>
                    {t(feature.NameKey)}
                  </dt>
                  <dd className="mt-2 text-base leading-7 text-gray-600">{t(feature.DescriptionKey)}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}
