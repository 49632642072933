import React from "react";
import {useTranslation} from "react-i18next";
import {keyFeatures} from "../data/keyFeatures";

const essentialFeatures = keyFeatures.filter((item) => item.EssentialFeature === true);

export default function EssentialFeatures() {
  const {t} = useTranslation();
  const {t: tFeatures} = useTranslation("features");

  return (
    <div className="bg-gray-900 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-base font-semibold leading-7 text-indigo-400">{t("EssentialFeatures.BadgeText")}</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">{t("EssentialFeatures.Title")}</p>
          <p className="mt-6 text-lg leading-8 text-gray-300">{t("EssentialFeatures.Description")}</p>
        </div>
        <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 text-base leading-7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-16">
          {essentialFeatures.map((feature, index) => (
            <div key={index} className="relative pl-9">
              <dt className="inline font-semibold text-white">
                <feature.Icon className="absolute left-1 top-1 h-5 w-5 text-indigo-500" aria-hidden="true" />
                {tFeatures(feature.TitleKey)}.
              </dt>{" "}
              <dd className="inline">{tFeatures(feature.DescriptionKey)}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
