import React from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {FiPhone} from "../assets/icons/icons";
import {getPath} from "../utils";

export default function GetInTouch({title}) {
  const {t} = useTranslation();

  return (
    <div className="container relative md:mt-32 mt-24">
      <div className="grid grid-cols-1 text-center">
        {title === true ? <h6 className="text-indigo-600 text-sm font-bold uppercase mb-2">{t("CTAs.GetInTouch.Title")}</h6> : ""}
        <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">{t("CTAs.GetInTouch.Header")}</h3>

        <p className="text-slate-600 max-w-xl mx-auto">{t("CTAs.GetInTouch.Description")}</p>

        <div className="mt-6">
          <Link
            to={getPath("/contact-us")}
            className="bg-indigo-600 text-white items-center shadow-sm hover:bg-indigo-500 rounded-md px-5 py-2 text-center font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 inline-flex align-middle">
            <FiPhone className="me-1" /> {t("CTAs.GetInTouch.Button")}
          </Link>
        </div>
      </div>
    </div>
  );
}
