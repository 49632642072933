import React from "react";
import {useTranslation} from "react-i18next";
import {useState, useEffect} from "react";
import {Link, useLocation} from "react-router-dom";
import {classNames, getPath} from "../utils";
import {TiArrowRight, FaRegQuestionCircle, FiLifeBuoy, AiOutlineYoutube} from "../assets/icons/icons";
import {keyFeatures} from "../data/keyFeatures";

import logo_dark from "../assets/images/logo-dark.png";
import logo_light from "../assets/images/logo-light.png";

export default function Navbar(props) {
  let {navClass, navJustify} = props;
  let [isMenu, setIsMenu] = useState(false);
  let [menu, setMenu] = useState("");

  const {t} = useTranslation();
  const {t: tFeatures} = useTranslation("features");
  const location = useLocation();

  useEffect(() => {
    let currentPath = location.pathname;
    let current = currentPath.substring(currentPath.lastIndexOf("/") + 1);

    setMenu(current);

    function windowScroll() {
      const navbar = document.getElementById("topnav");
      if (document.body.scrollTop >= 50 || document.documentElement.scrollTop >= 50) {
        if (navbar !== null) {
          navbar?.classList.add("nav-sticky");
        }
      } else {
        if (navbar !== null) {
          navbar?.classList.remove("nav-sticky");
        }
      }
    }

    window.addEventListener("scroll", windowScroll);
    window.scrollTo(0, 0);

    return () => {
      window.removeEventListener("scroll", windowScroll);
    };
  }, [location.pathname]);

  const toggleMenu = () => {
    setIsMenu(!isMenu);
    if (document.getElementById("navigation")) {
      const anchorArray = Array.from(document.getElementById("navigation").getElementsByTagName("a"));
      anchorArray.forEach((element) => {
        element.addEventListener("click", (elem) => {
          const target = elem.target.getAttribute("href");
          if (target !== "") {
            if (elem.target.nextElementSibling) {
              var submenu = elem.target.nextElementSibling.nextElementSibling;
              submenu.classList.toggle("open");
            }
          }
        });
      });
    }
  };

  return (
    <nav id="topnav" className={`defaultscroll ${navClass === "nav-light" ? "" : navClass === "nav-sticky" ? "bg-white dark:bg-slate-900" : ""}`}>
      <div className="container relative">
        {navClass === "nav-light" ? (
          <Link className="logo" to={getPath("/")}>
            <span className="inline-block dark:hidden">
              <img src={logo_dark} className="l-dark max-w-32" alt="" />
              <img src={logo_light} className="l-light max-w-32" alt="" />
            </span>
            <img src={logo_light} className="hidden dark:inline-block max-w-32" alt="" />
          </Link>
        ) : (
          <Link className="logo" to={getPath("/")}>
            <img src={logo_light} className="inline-block dark:hidden max-w-32" alt="" />
            <img src={logo_dark} className="hidden dark:inline-block max-w-32" alt="" />
          </Link>
        )}

        <div className="menu-extras">
          <div className="menu-item">
            <Link to="#" className={`navbar-toggle ${isMenu ? "open" : ""}`} id="isToggle" onClick={() => toggleMenu()}>
              <div className="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </Link>
          </div>
        </div>

        <ul className="signup-button list-none space-x-1 mb-0 hidden md:block">
          <li className="inline mb-0">
            <Link
              to="https://app.sellthis.com/account/register"
              target="_blank"
              className="bg-indigo-600 text-white shadow-sm hover:bg-indigo-500 rounded-md px-3 py-2 text-center font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 text-sm">
              {t("PageNames.SignUp")}
            </Link>
          </li>
          <li className="inline mb-0">
            <Link
              to="https://app.sellthis.com/account/login"
              target="_blank"
              className="py-[5px] px-3 inline-flex font-semibold align-middle items-center text-sm text-center hover:text-slate-500  text-slate-900 dark:text-white dark:hover:text-slate-900">
              {t("PageNames.LogIn")}
              <TiArrowRight className="" />
            </Link>
          </li>
        </ul>

        <div id="navigation" style={{display: isMenu ? "block" : "none"}}>
          <ul className={`navigation-menu ${navClass} ${navJustify}`}>
            <li className={classNames(keyFeatures.map((feature) => feature.PageURL).includes(menu) ? "active" : "", "has-submenu parent-parent-menu-item")}>
              <Link to="#" className="lg:text-sm py-3 lg:py-6 font-semibold leading-6 text-gray-900">
                {t("PageNames.Features")}
              </Link>
              <span className="menu-arrow"></span>
              <ul className="submenu megamenu flex-auto overflow-hidden bg-white text-sm leading-6 lg:shadow-lg lg:ring-1 lg:ring-gray-900/5 py-0">
                <div className="grid grid-cols-1 gap-x-6 gap-y-1 lg:p-4 lg:grid-cols-3">
                  {keyFeatures.map((item, index) => (
                    <li key={index} className={classNames(menu === item.PageURL ? "active" : "")}>
                      <div className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50">
                        <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                          <item.Icon className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" />
                        </div>
                        <div>
                          <Link to={getPath(`/features/${item.PageURL}`)} className="font-semibold text-gray-900">
                            {tFeatures(item.TitleKey)}
                            <span className="absolute inset-0"></span>
                          </Link>
                          <p className="mt-1 text-gray-600">{tFeatures(item.ShortDescriptionKey)}</p>
                        </div>
                      </div>
                    </li>
                  ))}
                </div>
              </ul>
            </li>

            <li className={menu === "pricing" ? "active" : ""}>
              <Link to={getPath("/pricing")} className="lg:text-sm py-3 lg:py-6 font-semibold leading-6 text-gray-900">
                {t("PageNames.Pricing")}
              </Link>
            </li>

            <li className={`${["faqs"].includes(menu) ? "active" : ""} has-submenu parent-parent-menu-item`}>
              <Link to="#" className="lg:text-sm py-3 lg:py-6 font-semibold leading-6 text-gray-900">
                {t("PageNames.Resources")}
              </Link>
              <span className="menu-arrow"></span>
              <ul className="submenu text-nowrap flex-auto overflow-hidden lg:rounded-3xl bg-white text-sm leading-6 lg:shadow-lg lg:ring-1 lg:ring-gray-900/5">
                <li className="lg:px-4 mb-1">
                  <div className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50">
                    <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                      <AiOutlineYoutube className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" />
                    </div>
                    <div>
                      <Link to={t("Links.YouTubeChannelURL")} target="_blank" className="font-semibold text-gray-900">
                        {t("Resources.YouTube.Name")}
                        <span className="absolute inset-0"></span>
                      </Link>
                      <p className="mt-1 text-gray-600">{t("Resources.YouTube.Description")}</p>
                    </div>
                  </div>
                </li>
                <li className="lg:px-4 mb-1">
                  <div className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50">
                    <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                      <FiLifeBuoy className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" />
                    </div>
                    <div>
                      <Link to={t("Links.HelpCenterURL")} target="_blank" className="font-semibold text-gray-900">
                        {t("Resources.HelpCenter.Name")}
                        <span className="absolute inset-0"></span>
                      </Link>
                      <p className="mt-1 text-gray-600">{t("Resources.HelpCenter.Description")}</p>
                    </div>
                  </div>
                </li>
                <li className={classNames(menu === "faqs" ? "active" : "", "lg:px-4")}>
                  <div className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50">
                    <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                      <FaRegQuestionCircle className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" />
                    </div>
                    <div>
                      <Link to={getPath("/faqs")} className="font-semibold text-gray-900">
                        {t("Resources.FAQs.Name")}
                        <span className="absolute inset-0"></span>
                      </Link>
                      <p className="mt-1 text-gray-600">{t("Resources.FAQs.Description")}</p>
                    </div>
                  </div>
                </li>
              </ul>
            </li>

            <li className={menu === "affiliate" ? "active" : ""}>
              <Link to={getPath("/affiliate")} className="lg:text-sm py-3 lg:py-6 font-semibold leading-6 text-gray-900">
                {t("PageNames.Affiliate")}
              </Link>
            </li>

            <li className={menu === "contact-us" ? "active" : ""}>
              <Link to={getPath("/contact-us")} className="lg:text-sm py-3 lg:py-6 font-semibold leading-6 text-gray-900">
                {t("PageNames.ContactUs")}
              </Link>
            </li>
          </ul>

          <ul className="flex space-x-1 md:hidden mx-5 mt-2 mb-4 overflow-hidden pt-3 border-gray-500/10 border-t items-center h-16">
            <li>
              <Link
                to="https://app.sellthis.com/account/register"
                target="_blank"
                className="bg-indigo-600 text-white shadow-sm hover:bg-indigo-500 rounded-md px-3 py-2 text-center font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 text-sm">
                {t("PageNames.SignUp")}
              </Link>
            </li>
            <li>
              <Link
                to="https://app.sellthis.com/account/login"
                target="_blank"
                className="py-[5px] px-3 inline-flex font-semibold align-middle items-center text-sm text-center hover:text-slate-500 text-slate-900 dark:text-white dark:hover:text-slate-900">
                {t("PageNames.LogIn")}
                <TiArrowRight className="" />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
