import axios from "axios";

export const sendContactForm = async (formData) => {
  try {
    const response = await axios.post("https://identity.sellthis.com/api/contact/submitContactForm", formData);
    return response.data; // Assuming the API returns a JSON object with status and message
  } catch (error) {
    return {success: false, message: "ContactUs.ContactForm.SubmitError"};
  }
};
