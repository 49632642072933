import React, {useState} from "react";
import {useTranslation} from "react-i18next";

import {faqsData} from "../data/faqs";
import {classNames} from "../utils";

export default function KeyFeatures() {
  const {t} = useTranslation();

  let [activeIndex, setActiveIndex] = useState(0);

  let toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(0);
    } else {
      setActiveIndex(index);
    }
  };

  return (
    <div className="container relative">
      <div className="py-24 sm:py-32">
        <div className="grid grid-cols-1 pb-8 text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-600">{t("FAQCommonQuestions")}</h2>
          <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">{t("FAQTitle")}</p>

          <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">{t("FAQDescriptionAltHome")}</p>
        </div>

        <div className="relative grid md:grid-cols-12 grid-cols-1 items-start mt-8 gap-[30px]">
          <div className="md:col-span-6">
            <div className="relative">
              <div className="relative rounded-xl overflow-hidden shadow-md dark:shadow-gray-800">
                <div className="w-full py-72 bg-slate-400 bg-[url('../../assets/images/faqs.jpg')] bg-no-repeat bg-bottom bg-cover jarallax" data-jarallax data-speed="0.5"></div>
              </div>
            </div>
          </div>

          <div className="md:col-span-6">
            <div id="accordion-collapse">
              {faqsData
                .filter((item) => item.FeaturedInHomePage === true)
                .map((item, index) => (
                  <div key={index} className={classNames(index === 0 ? "mt-1" : "mt-4", "relative shadow dark:shadow-gray-800 rounded-md overflow-hidden")}>
                    <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                      <button
                        type="button"
                        onClick={() => toggleAccordion(index)}
                        className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === index ? "bg-gray-50 dark:bg-slate-800 text-indigo-600" : ""}`}
                        data-accordion-target="#accordion-collapse-body-1"
                        aria-expanded="true"
                        aria-controls="accordion-collapse-body-1">
                        <span
                          className="text-base font-semibold leading-7"
                          dangerouslySetInnerHTML={{
                            __html: t(`FaqsData.${item.Category}.${item.Id}.Q`),
                          }}></span>
                        <svg
                          data-accordion-icon
                          className={`${activeIndex === index ? "rotate-180" : "rotate-270"} size-4 shrink-01`}
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                        </svg>
                      </button>
                    </h2>
                    {activeIndex === index && (
                      <div>
                        <div className="p-5">
                          <p
                            className="text-base leading-7 text-gray-600"
                            dangerouslySetInnerHTML={{
                              __html: t(`FaqsData.${item.Category}.${item.Id}.A`),
                            }}></p>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
