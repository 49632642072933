import {useState} from "react";
import Navbar from "../component/navbar";
import Footer from "../component/footer";
import GetInTouch from "../component/getInTouch";
import {useTranslation} from "react-i18next";
import {Radio, RadioGroup} from "@headlessui/react";
import {CheckIcon} from "@heroicons/react/20/solid";
import {faqsData} from "../data/faqs";
import {pricing} from "../data/pricing";
import {flattenArray, classNames} from "../utils";

export default function Pricing() {
  const {t} = useTranslation();
  const {t: tTestimonials} = useTranslation("testimonials");

  document.title = t("PageTitles.Pricing");

  const [frequency, setFrequency] = useState(pricing.frequencies[0]);

  const testimonials = tTestimonials("Testimonials", {returnObjects: true});
  const flattenedTestimonials = flattenArray(testimonials);
  const featuredTestimonials = flattenedTestimonials.filter((testimonial) => testimonial.Featured);

  return (
    <>
      <Navbar />
      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
            <div
              className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
          <div className="mx-auto mt-16 max-w-7xl sm:mt-32">
            <div className="mx-auto max-w-4xl text-center">
              <h1 className="text-base font-semibold leading-7 text-indigo-600">{t("Pricing.BadgeText")}</h1>
              <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">{t("Pricing.Headline")}</p>
            </div>
            <p
              className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600"
              dangerouslySetInnerHTML={{
                __html: t("Pricing.HeadlineDescription"),
              }}></p>

            <div className="mt-16 flex justify-center">
              <fieldset aria-label="Payment frequency">
                <RadioGroup value={frequency} onChange={setFrequency} className="gap-x-1 rounded-full p-3 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200">
                  {pricing.frequencies.map((option, index) => (
                    <Radio key={index} value={option} className={({checked}) => classNames(checked ? "bg-indigo-600 text-white" : "text-gray-600", "cursor-pointer rounded-full px-3 py-1.5")}>
                      {t(option.Label)} {option.Discount && <span className="bg-amber-500 text-white text-xs font-bold px-2.5 py-0.5 rounded-full  ms-1">{t(option.Discount)}</span>}
                    </Radio>
                  ))}
                </RadioGroup>
              </fieldset>
            </div>
            <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              {pricing.tiers.map((tier) => (
                <div key={tier.Id} className={classNames(tier.MostPopular ? "ring-2 ring-indigo-600" : "ring-1 ring-gray-200", "rounded-3xl p-8 xl:p-10")}>
                  <div className="flex items-center justify-between gap-x-4">
                    <h2 id={tier.Id} className={classNames(tier.MostPopular ? "text-indigo-600" : "text-gray-900", "text-lg font-semibold leading-8")}>
                      {tier.Name}
                    </h2>
                    {tier.MostPopular ? <p className="rounded-full bg-indigo-500 px-2.5 py-1 text-xs font-semibold leading-5 text-white">{t("MostPopular")}</p> : null}
                  </div>
                  <p className="mt-4 text-sm leading-6 text-gray-600">{t(tier.Description)}</p>
                  <p className="mt-6 flex items-baseline gap-x-1">
                    <span className="text-4xl font-bold tracking-tight text-gray-900">{tier.Price[frequency.Value]}</span>
                    <span className="text-sm font-semibold leading-6 text-gray-600">{t(frequency.PriceSuffix)}</span>
                  </p>
                  <a
                    href={`https://app.sellthis.com/account/register?tier=${tier.Id.toLowerCase()}&frequency=${frequency.Value.toLowerCase()}`}
                    aria-describedby={tier.Id}
                    className={classNames(
                      tier.MostPopular ? "bg-indigo-600 text-white shadow-sm hover:bg-indigo-500" : "text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300",
                      "mt-6 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
                    )}>
                    {t("TryForFree")}
                  </a>
                  <ul className="mt-8 space-y-3 text-sm leading-6 text-gray-600  xl:mt-10">
                    {tier.Features.map((feature, index) => (
                      <li key={index} className="flex gap-x-3">
                        <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
                        {t(feature)}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>

          {/* Testimonial section */}
          <div className="bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl">
              <div className="mx-auto max-w-xl text-center">
                <h2 className="text-lg font-semibold leading-8 tracking-tight text-indigo-600">{t("Testimonials.Title")}</h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{t("Testimonials.DescriptionInPricing")}</p>
              </div>
              <div className="mx-auto mt-16 flow-root max-w-2xl sm:mt-20 lg:mx-0 lg:max-w-none">
                <div className="-mt-8 sm:-mx-4 sm:columns-2 sm:text-[0] lg:columns-3">
                  {featuredTestimonials.map((testimonial, index) => (
                    <div key={index} className="pt-8 sm:inline-block sm:w-full sm:px-4">
                      <figure className="rounded-2xl bg-gray-50 p-8 text-sm leading-6">
                        <blockquote className="text-gray-900">
                          <p>{`“${testimonial.Body}”`}</p>
                        </blockquote>
                        <figcaption className="mt-6 flex items-center gap-x-4">
                          {testimonial.Author.ImageUrl && <img className="h-10 w-10 rounded-full bg-gray-50" src={testimonial.Author.ImageUrl} alt="" />}
                          <div>
                            <div className="font-semibold text-gray-900">{testimonial.Author.Name}</div>
                            {testimonial.Author.Position && <div className="text-gray-600">{testimonial.Author.Position}</div>}
                          </div>
                        </figcaption>
                      </figure>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* FAQ section */}
          <div className="mx-auto mt-24 max-w-7xl sm:mt-50">
            <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">{t("FAQTitle")}</h2>
            <p
              className="mt-6 max-w-2xl text-base leading-7 text-gray-600"
              dangerouslySetInnerHTML={{
                __html: t("FAQDescription"),
              }}></p>
            <div className="mt-20">
              <dl className="space-y-16 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:grid-cols-3 lg:gap-x-10">
                {faqsData
                  .filter((item) => item.FeaturedInPricing === true)
                  .map((item, index) => (
                    <div key={index}>
                      <dd
                        className="text-base font-semibold leading-7 text-gray-900"
                        dangerouslySetInnerHTML={{
                          __html: t(`FaqsData.${item.Category}.${item.Id}.Q`),
                        }}></dd>
                      <dd
                        className="mt-2 text-base leading-7 text-gray-600"
                        dangerouslySetInnerHTML={{
                          __html: t(`FaqsData.${item.Category}.${item.Id}.A`),
                        }}></dd>
                    </div>
                  ))}
              </dl>
            </div>
          </div>

          <div className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]" aria-hidden="true">
            <div
              className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
        </div>
        <GetInTouch />
      </section>

      <Footer />
    </>
  );
}
