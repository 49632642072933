import React from "react";
import {Link} from "react-router-dom";
import {getPath} from "../utils";
import {useTranslation} from "react-i18next";
import Navbar from "../component/navbar";
import Footer from "../component/footer";
import {keyFeatures} from "../data/keyFeatures";

import GetInTouch from "../component/getInTouch";

export default function Features() {
  const {t} = useTranslation();
  const {t: tFeatures} = useTranslation("features");

  document.title = t("PageTitles.Features");

  return (
    <>
      <Navbar />

      <section className="relativemd:py-24 py-16">
        <div className="py-24 sm:py-22 lg:pb-30">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl text-center">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">{t("FeaturesPage.Headline")}</h1>
              <p className="mt-6 text-lg leading-8 text-gray-600">{t("FeaturesPage.HeadlineDescription")}</p>
            </div>

            <div className="mx-auto mt-16 max-w-7xl sm:mt-32 ">
              <ul className="grid grid-cols-1 gap-x-12 gap-y-16 sm:grid-cols-2 lg:grid-cols-3">
                {keyFeatures.map((item, index) => {
                  const Icon = item.Icon;
                  return (
                    <li key={index} className="group relative flex flex-col items-start">
                      <div className="relative z-10 flex h-12 w-12 items-center justify-center rounded-full bg-white shadow-md shadow-zinc-800/5 ring-1 ring-zinc-900/5 dark:border dark:border-zinc-700/50 dark:bg-zinc-800 dark:ring-0">
                        <Icon className="size-5" />
                      </div>
                      <h2 className="mt-6 text-base font-semibold text-zinc-800 dark:text-zinc-100">
                        <div className="absolute -inset-x-4 -inset-y-6 z-0 scale-95 bg-zinc-50 opacity-0 transition group-hover:scale-100 group-hover:opacity-100 sm:-inset-x-6 sm:rounded-2xl dark:bg-zinc-800/50"></div>
                        <Link to={getPath(`/features/${item.PageURL}`)}>
                          <span className="absolute -inset-x-4 -inset-y-6 z-20 sm:-inset-x-6 sm:rounded-2xl"></span>
                          <span className="relative z-10">{tFeatures(item.TitleKey)}</span>
                        </Link>
                      </h2>
                      <p className="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">{tFeatures(item.DescriptionKey)}</p>
                      <p className="relative z-10 mt-6 flex text-sm font-medium transition group-hover:text-teal-500 dark:text-zinc-200">
                        <span className="text-sm font-semibold leading-6 text-indigo-600">{t("LearnMore")} →</span>
                      </p>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>

        <GetInTouch />
      </section>

      <Footer />
    </>
  );
}
