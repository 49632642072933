export const pricing = {
  frequencies: [
    {Value: "Monthly", Label: "Pricing.Frequencies.Monthly.Label", PriceSuffix: "Pricing.Frequencies.Monthly.PriceSuffix"},
    {Value: "Month6", Label: "Pricing.Frequencies.Month6.Label", PriceSuffix: "Pricing.Frequencies.Month6.PriceSuffix", Discount: "Pricing.Frequencies.Month6.Discount"},
    {Value: "Annually", Label: "Pricing.Frequencies.Annually.Label", PriceSuffix: "Pricing.Frequencies.Annually.PriceSuffix", Discount: "Pricing.Frequencies.Annually.Discount"},
  ],
  tiers: [
    {
      Name: "Basic",
      Id: "basic",
      Price: {Monthly: "$59.9", Month6: "$299.9", Annually: "$599.9"},
      Description: "Pricing.Tiers.Basic.Description",
      Features: ["Pricing.Tiers.Basic.Features.Feature1", "Pricing.Tiers.Basic.Features.Feature2", "Pricing.Tiers.Basic.Features.Feature3", "Pricing.Tiers.Basic.Features.Feature4"],
      MostPopular: false,
    },
    {
      Name: "Premium",
      Id: "premium",
      Price: {Monthly: "$199.9", Month6: "$999.9", Annually: "$1,999.9"},
      Description: "Pricing.Tiers.Premium.Description",
      Features: [
        "Pricing.Tiers.Premium.Features.Feature1",
        "Pricing.Tiers.Premium.Features.Feature2",
        "Pricing.Tiers.Premium.Features.Feature3",
        "Pricing.Tiers.Premium.Features.Feature4",
        "Pricing.Tiers.Premium.Features.Feature5",
      ],
      MostPopular: true,
    },
    {
      Name: "Professional",
      Id: "professional",
      Price: {Monthly: "$79.9", Month6: "$399.9", Annually: "$799.9"},
      Description: "Pricing.Tiers.Professional.Description",
      Features: [
        "Pricing.Tiers.Professional.Features.Feature1",
        "Pricing.Tiers.Professional.Features.Feature2",
        "Pricing.Tiers.Professional.Features.Feature3",
        "Pricing.Tiers.Professional.Features.Feature4",
      ],
      MostPopular: false,
    },
  ],
};
