import {
  FaFunnelDollar,
  FaAmazon,
  MdOutlineScience,
  MdOutlineInventory,
  RiBubbleChartLine,
  SiAlwaysdata,
  TbLayoutDashboard,
  FaStore,
  RiShoppingBagLine,
  FaRegCreditCard,
  BsCurrencyExchange,
  TbSettingsSearch,
  BiWallet,
  TiWarningOutline,
} from "../assets/icons/icons";
import {FaBoxesPacking, FaMoneyBillTransfer, FaMagnifyingGlassChart} from "react-icons/fa6";
import {IoBugOutline} from "react-icons/io5";

export const keyFeatures = [
  {
    Icon: FaAmazon,
    Key: "CrossBorderArbitrage",
    TitleKey: "Features.CrossBorderArbitrage.Title",
    DescriptionKey: "Features.CrossBorderArbitrage.Description",
    ShortDescriptionKey: "Features.CrossBorderArbitrage.ShortDescription",
    PageURL: "cross-border-arbitrage",
    FeaturedInHomePage: true,
    FeaturedInFooter: true,
    EssentialFeature: true,
  },
  {
    Icon: FaBoxesPacking,
    Key: "ShipmentManagement",
    TitleKey: "Features.ShipmentManagement.Title",
    DescriptionKey: "Features.ShipmentManagement.Description",
    ShortDescriptionKey: "Features.ShipmentManagement.ShortDescription",
    PageURL: "shipment-management",
    FeaturedInHomePage: true,
    FeaturedInFooter: true,
    EssentialFeature: true,
  },
  {
    Icon: BiWallet,
    Key: "ProfitCalculator",
    TitleKey: "Features.ProfitCalculator.Title",
    DescriptionKey: "Features.ProfitCalculator.Description",
    ShortDescriptionKey: "Features.ProfitCalculator.ShortDescription",
    PageURL: "profit-calculator",
    FeaturedInHomePage: true,
    FeaturedInFooter: true,
    EssentialFeature: true,
  },
  {
    Icon: MdOutlineScience,
    Key: "DetailedProductAnalysis",
    TitleKey: "Features.DetailedProductAnalysis.Title",
    DescriptionKey: "Features.DetailedProductAnalysis.Description",
    ShortDescriptionKey: "Features.DetailedProductAnalysis.ShortDescription",
    PageURL: "detailed-product-analysis",
    FeaturedInHomePage: true,
    EssentialFeature: true,
  },
  {
    Icon: MdOutlineInventory,
    Key: "InventoryInsights",
    TitleKey: "Features.InventoryInsights.Title",
    DescriptionKey: "Features.InventoryInsights.Description",
    ShortDescriptionKey: "Features.InventoryInsights.ShortDescription",
    PageURL: "inventory-insights",
    FeaturedInHomePage: true,
  },
  {
    Icon: FaMoneyBillTransfer,
    Key: "ROICalculation",
    TitleKey: "Features.ROICalculation.Title",
    DescriptionKey: "Features.ROICalculation.Description",
    ShortDescriptionKey: "Features.ROICalculation.ShortDescription",
    PageURL: "roi-calculation",
    FeaturedInHomePage: true,
    FeaturedInFooter: true,
  },
  {
    Icon: RiBubbleChartLine,
    Key: "DataDrivenDecisions",
    TitleKey: "Features.DataDrivenDecisions.Title",
    DescriptionKey: "Features.DataDrivenDecisions.Description",
    ShortDescriptionKey: "Features.DataDrivenDecisions.ShortDescription",
    PageURL: "data-driven-decisions",
  },
  {
    Icon: SiAlwaysdata,
    Key: "AdvancedDataAnalysis",
    TitleKey: "Features.AdvancedDataAnalysis.Title",
    DescriptionKey: "Features.AdvancedDataAnalysis.Description",
    ShortDescriptionKey: "Features.AdvancedDataAnalysis.ShortDescription",
    PageURL: "advanced-data-analysis",
    FeaturedInHomePage: true,
    FeaturedInFooter: true,
  },
  {
    Icon: FaMagnifyingGlassChart,
    Key: "CompetitorAnalysis",
    TitleKey: "Features.CompetitorAnalysis.Title",
    DescriptionKey: "Features.CompetitorAnalysis.Description",
    ShortDescriptionKey: "Features.CompetitorAnalysis.ShortDescription",
    PageURL: "competitor-analysis",
    FeaturedInHomePage: true,
  },
  {
    Icon: TbLayoutDashboard,
    Key: "ComprehensiveDashboard",
    TitleKey: "Features.ComprehensiveDashboard.Title",
    DescriptionKey: "Features.ComprehensiveDashboard.Description",
    ShortDescriptionKey: "Features.ComprehensiveDashboard.ShortDescription",
    PageURL: "comprehensive-dashboard",
    FeaturedInFooter: true,
    EssentialFeature: true,
  },
  {
    Icon: FaStore,
    Key: "MultiMarketplaceIntegration",
    TitleKey: "Features.MultiMarketplaceIntegration.Title",
    DescriptionKey: "Features.MultiMarketplaceIntegration.Description",
    ShortDescriptionKey: "Features.MultiMarketplaceIntegration.ShortDescription",
    PageURL: "multi-marketplace-integration",
    FeaturedInHomePage: true,
    FeaturedInFooter: true,
  },
  {
    Icon: TiWarningOutline,
    Key: "ProductRestrictionChecks",
    TitleKey: "Features.ProductRestrictionChecks.Title",
    DescriptionKey: "Features.ProductRestrictionChecks.Description",
    ShortDescriptionKey: "Features.ProductRestrictionChecks.ShortDescription",
    PageURL: "product-restriction-checks",
  },
  {
    Icon: FaFunnelDollar,
    Key: "TaxSettingsConfiguration",
    TitleKey: "Features.TaxSettingsConfiguration.Title",
    DescriptionKey: "Features.TaxSettingsConfiguration.Description",
    ShortDescriptionKey: "Features.TaxSettingsConfiguration.ShortDescription",
    PageURL: "tax-settings-configuration",
  },
  {
    Icon: RiShoppingBagLine,
    Key: "SalesForecasting",
    TitleKey: "Features.SalesForecasting.Title",
    DescriptionKey: "Features.SalesForecasting.Description",
    ShortDescriptionKey: "Features.SalesForecasting.ShortDescription",
    PageURL: "sales-forecasting",
    FeaturedInFooter: true,
    EssentialFeature: true,
  },
  {
    Icon: FaRegCreditCard,
    Key: "CustomizableCostsFees",
    TitleKey: "Features.CustomizableCostsFees.Title",
    DescriptionKey: "Features.CustomizableCostsFees.Description",
    ShortDescriptionKey: "Features.CustomizableCostsFees.ShortDescription",
    PageURL: "customizable-costs-fees",
  },
  {
    Icon: TbSettingsSearch,
    Key: "CustomizableAnalyzeSettings",
    TitleKey: "Features.CustomizableAnalyzeSettings.Title",
    DescriptionKey: "Features.CustomizableAnalyzeSettings.Description",
    ShortDescriptionKey: "Features.CustomizableAnalyzeSettings.ShortDescription",
    PageURL: "customizable-analyze-settings",
    EssentialFeature: true,
  },
  {
    Icon: BsCurrencyExchange,
    Key: "AutomatedExchangeRate",
    TitleKey: "Features.AutomatedExchangeRate.Title",
    DescriptionKey: "Features.AutomatedExchangeRate.Description",
    ShortDescriptionKey: "Features.AutomatedExchangeRate.ShortDescription",
    PageURL: "automated-exchange-rate",
  },
  {
    Icon: IoBugOutline,
    Key: "ScanAndSave",
    TitleKey: "Features.ScanAndSave.Title",
    DescriptionKey: "Features.ScanAndSave.Description",
    ShortDescriptionKey: "Features.ScanAndSave.ShortDescription",
    PageURL: "scan-and-save",
    FeaturedInFooter: true,
    EssentialFeature: true,
  },
];
